 .App {
  min-height: 100%;
}
/* Section Titles */
h4 {
  color: white;
  font-size: x-large;
  display:flex;
  align-items: center;
  font-family:'Roboto', sans-serif;
  align-self: flex-start;
 animation-delay: 0.2s !important;
  margin-left: 15%;
}
/* add a short line before title */
h4::before {
  content: '';
  display:inline-block;
  background-color: rgba(255, 255, 255, 0.534);
  width: 40px;
  height: 2px;
  transform: translateX(-10px);
}
/* Add a longer line after title */
h4::after {
  content: '';
  display:inline-block;
  background-color: rgba(255, 255, 255, 0.534);
  width: 200px;
  height: 2px;
  transform: translateX(10px);
 
  
}

/* Picture of me, with a fade in from the top animation */
  .picture {
    animation: fade-in-top 1 0.5s linear;
    transform: translateY(-11%);
    animation-fill-mode: forwards;
  
  }

  .AboutMe {
    display:flex;
    justify-content:center;
    align-items: center;
    height:100vh;
    width:100vw;
    flex-direction:column;

  }

  .desc {
    animation: fade-in 1 0.5s linear;
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fade-in-top {
  from {
    
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* @keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

@keyframes fade-in { 
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  85% {
    
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Github {
  animation: fade-in 1 0.5s linear;
  animation-fill-mode: forwards;

  opacity:0;
}
.LinkedIn {
  animation: fade-in 1 0.5s linear 0.5s;
  animation-fill-mode: forwards;

  opacity:0;
}
.Translate {
  animation: fade-in 1 0.5s linear 1s;
  animation-fill-mode: forwards;
  opacity:0;
}

.AppBar {
  animation: fade-in-top 1 0.7s linear;
  animation-fill-mode: forwards;
  transform: translateY(-100%);

}


.AppBarContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AppBarTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AppBarLinks {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.AppBarButtons {
  font-weight: bold;
  margin-left: 10px;
}

.HomePageContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  min-height: 100vh;
  
  background-color: #797B84;
}

@media (max-width: 800px) and (min-width: 601px) { .AboutMe > img {height: 35vw} .desc{width: 90% !important;}}
@media (max-width: 600px) { .AboutMe > img { height: 60vw !important; } .desc { width: 95% !important;}}

@media (max-width: 1500px) and (min-width: 801px) {
  .HomePageContent {
    flex: 100%;
   
    
  }

  .HomePageContent > img {
    width: 27vw !important;

  }

  .desc {
    width: 40% !important;
   
   
  }
}

.Project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:white;
}

.Project > img {
  width:65vw;
  max-width: 1000px;
  border-radius: 15px;
  border: 1px solid white;
}

.Project > h5 {
  font-size: x-large;
}

.DefaultDesc {
  min-height:20vh;
  width: 44vw;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content:space-between;
  background: rgba(0, 0, 0, 0.486);
  margin-top: 30px;
  top: -10vh;
}



.Habeuro {
  align-self: flex-start;
  display: flex;
  margin-left: 15%;
  margin-top: 5%;
  transform: translateX(-10px);
  align-items: center;
  color:white;
}

.QrDocent {
  align-self: flex-end;
  display: flex;
  margin-right: 15%;
  margin-top: 7.5%;
  margin-bottom: 2.5%;
  transform: translateX(10px);
  align-items: center;
  color:white;
}

.Habeuro > img, .QrDocent > img {
  width:40vw;
  border-radius: 15px;
  border: 1px solid white;

  /* border: 1px solid #cf6a6a; */
  
}

.goodAt {
  display: flex;
  justify-content:space-between;
  text-align: center;
  color: white;
  font-size: x-large;
  width:73%;
  margin-top: 6%;
}

.progLang {
  color: rgba(255, 255, 255, 0.534);
  display: flex;
  justify-content:flex-start;
  width:60%;
  font-size: x-large;
  flex-wrap: wrap;
  align-self: center;
  font-family: 'Ubuntu', sans-serif;
  margin-top: 6%;
}

.goodDesc {
  align-items: center;
  justify-content: center;
  display: flex;
  color: rgba(255, 255, 255, 0.534);
}

.HabeuroDesc, .QrDocentDesc {
  min-height:20vh;
  width: 41vw;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content:space-between;
  background: rgba(0, 0, 0, 0.486);
}

.HabeuroDesc { right:10vw}
.QrDocentDesc { left:10vw}

.HabeuroDesc > h5 {
  position: absolute;
  font-size: x-large;
  right: 1%;
  top: -80px;
}

.QrDocentDesc > h5 {
  position: absolute;
  font-size: x-large;
  left: 1%;
  top: -80px;
}

.projectDesc{
  padding-left:3%;
  padding-right:3%;
  padding-top:3%;
  /* margin-bottom: 10%; */
  
}

p > a {
  color: #FF5733;
}
.techStack{
  /* position: absolute;
  bottom: 0px; */
  padding-left:3%;
  padding-right:3%;
  margin-right:7%;
  color:rgba(255, 255, 255, 0.589);
}

.gitLink {
  position: absolute;
  bottom: 0px;
  right: 5px;
  padding-left:3%;
  color:rgba(255, 255, 255, 0.589);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .HabeuroDesc, .QrDocentDesc, .DefaultDesc {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

